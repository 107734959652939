import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabValuesDropOff: 1,
};

export const tabValuesDropOffSlice = createSlice({
  name: "tabValuesDropOff",
  initialState,
  reducers: {
    getTabValuesDropOff: (state, action) => {
      state.tabValuesDropOff = action.payload;
    },
  },
});

export const tabValuesDropOffSliceAction = tabValuesDropOffSlice.actions;
