import React, { useEffect, useRef, useState } from "react";
import {
  Outlet,
  useTheme,
  CssBaseline,
  Toolbar,
  AppBar,
  Box,
  apiEndPointsConfig,
  Drawer,
  Button,
} from "../../collections/Imports";
import Header from "./Header";
import SideBar from "./Sidebar/SideBar";
import { useDispatch } from "react-redux";
import { addAllPermissionsStatusActions } from "store/permissionsState";
import apiService from "helpers/apiService";
import { userRepoActions } from "store/userRepo/UserRepo";

import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";

const MainLayout = () => {
  const theme = useTheme();

  const [isMobileView, setIsMobileView] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // Function to check window width and update isMobileView state
    const checkWindowSize = () => {
      setIsMobileView(window.innerWidth <= 900);
    };
    // Call checkWindowSize on initial render
    checkWindowSize();
    // Add event listener to update isMobileView on window resize
    window.addEventListener("resize", checkWindowSize);
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, [window]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await apiService.get(apiEndPointsConfig.loginRepo, {
          data: "",
        });
        const permissionData = [...res.data.data];
        const data = permissionData.map((res) => ({
          route: res?.to,
          permissions: {
            readContent: res.permissions.includes("Read Content"),
            writeContent: res.permissions.includes("Write Content"),
            modifyContent: res.permissions.includes("Modify Content"),
            publishContent: res.permissions.includes("Publish Content"),
            admin: res.permissions.includes("Admin"),
          },
        }));

        dispatch(userRepoActions.getRepo(res.data.data));
        dispatch(addAllPermissionsStatusActions.addAllPermissionsStatus(data));
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here
      }
    };

    fetchData(); // Call the function when the component is mounted

    const handleBeforeUnload = () => {
      // Call the function when the window is reloaded
      fetchData();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const openDrawer = () => {
    setIsMobileView((prevState) => !prevState); // Toggle the isMobileView state
  };

  const toolbarRef = useRef(null);

  // useEffect(() => {
  //     const handleClickOutside = (event) => {
  //         if (toolbarRef.current && !toolbarRef.current.contains(event.target) && !event.target.closest('.MuiButton-root')) {
  //             setIsMobileView((prevState) => !prevState);
  //         }
  //     };
  //     document.body.addEventListener('click', handleClickOutside);
  //     return () => {
  //         document.body.removeEventListener('click', handleClickOutside);
  //     };
  // }, []);

  return (
    <Box sx={{ height: "100vh" }}>
      <CssBaseline />
      <Box>
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
          }}
        >
          {localStorage.getItem("accessToken") ? (
            <>
              <Box>
                <Header />
              </Box>
              {/* mobile view button */}

              {!isMobileView ? (
                <Drawer
                  variant="permanent"
                  sx={{
                    width: 200,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                      width: 200,
                      boxSizing: "border-box",
                      zIndex: "-1",
                    },
                  }}
                >
                  <Toolbar
                    ref={toolbarRef}
                    sx={{ position: "absolute", width: "195px" }}
                  >
                    <SideBar openDrawer={openDrawer} />
                  </Toolbar>
                </Drawer>
              ) : (
                <Box>
                  <Button onClick={openDrawer}>
                    <Icon path={mdiMenu} size={1} />
                  </Button>
                </Box>
              )}
            </>
          ) : null}
        </AppBar>
        <Box
          sx={{
            ...theme.typography.mainContent,
            "@media (max-width: 375px)": { width: 375 },
            "@media (max-width: 900px)": { width: 768 },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;

//commit try
